<template>
<div class="container py-4" id="shoping-cart-breadcrumb">   
    <div class="shoping-cart-breadcrumb">
		<a href="javascript:void(0)" v-bind:class="[step >= 1 ? 'active' : '']" >
			<span class="breadcrumb__inner">
				<span class="breadcrumb__title">1. Koszyk i dostawa</span>
				<span class="breadcrumb__desc">Wybierz dostawę</span>
			</span>
		</a>
		<a  href="javascript:void(0)" v-bind:class="[step >= 2 ? 'active' : '']" >
			<span class="breadcrumb__inner">
				<span class="breadcrumb__title">2. Twoje dane</span>
				<span class="breadcrumb__desc">Uzupełnij dane</span>
			</span>
		</a>
		<a href="javascript:void(0)" v-bind:class="[step == 3 ? 'active' : '']">
			<span class="breadcrumb__inner">
				<span class="breadcrumb__title">3. Płatność i podsumowanie</span>
				<span class="breadcrumb__desc">Wybierz sposób płatności</span>
			</span>
		</a> 
    </div> 
</div>
</template> 
<script> 
    import {
        mapGetters,
        mapActions
    } from 'vuex';
	
	export default {
	props: ['step'],  
        computed: { 
            ...mapGetters(["cart_items", "cart", "delivery_update"]),
        }}
</script>