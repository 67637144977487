<!-- compare.vue -->

<template>
    <div>
      <form @submit.prevent="addToComparison">
        <div class="select_comparison">
          <button type="submit">
            <svg class="s16"><use xlink:href="#target"/></svg>
            Dodaj do porównania 
          </button>
        </div>
      </form>
    </div>
  </template>
  
  <script>
  export default {
    props: ['productId'],
    data() {
        return {
            csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        }
    },
    methods: {
      addToComparison() {
        axios.post(`/porownaj/${this.productId}`)
          .then(response => {
            console.log(response.data);
          })
          .catch(error => {
            console.error('Wystąpił błąd podczas dodawania do porównania', error);
          });
      }
    }
  };
  </script>
  
  <style scoped>
  .select_comparison button{
    border: none;
    background: none;
  }
</style>