<template>
    <div class="row card-coupon mt-5" v-if="cart && cart.count_items">
        <div class="col-md-12">
            <h5>Posiadasz kod rabatowy?</h5>
        </div>
        <form v-if="!cart.copupon" :action="route_add_coupon" method="post" class="col-12 col-md-9">
             <div class="form-label-group has-search input-group-sm">  
                    <svg  class="s24 form-control-feedback"><use xlink:href="#price-drop"/></svg> 
                    <input type="text" value=""  placeholder="Kod rabatowy" class="form-control" name="card_cod" required>
                    <label for="search">Kod rabatowy*</label>
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary mt-0 bg-blue" type="submit">Dodaj</button> 
                      </div> 
                </div>
                <input type="hidden" name="_token" :value="csrf"> 
        </form>
        <div v-else class="col-12 mt-2">
            Wprowadziłeś kod <strong>{{cart.copupon.code}}</strong> - <a :href="route_delete_coupon" title="Usuń kod"><i
                    class="fa fa-times" aria-hidden="true"></i> usuń</a> aby dodać nowy
        </div>
        <p class="col-12 mt-2"><small class="small">*Promocje nie łączą się</small></p>
    </div>
</template>


<script>
    import {
        mapGetters,
        mapActions
    } from 'vuex'
    export default {
        props: ['route_add_coupon', 'route_delete_coupon'],
        data() {
            return {
                csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            }
        },
        computed: {
            ...mapGetters(["cart_items", "cart", 'delivery_update']),
        },
        filters: {
            get_src_image: function (value) {
                if (!value) return ''
                value = JSON.parse(value);
                return value.src;
            },
            render_price: function (p) {
                p = parseFloat(p).toFixed(2).replace(".", ",") + ' zł';
                return p;
            }
        },
        mounted() {
            this.get_cart();
        },
        created() {},
        methods: {
            ...mapActions(['get_cart', 'removeItem', 'changeDelivery']),
        },
    }
</script>