<template>
      <div class="row" v-if="is_load == 1">
        <div class="col-12 cart-product-list" v-if="cart && cart.count_items">
          <div class="container heading d-none d-sm-block"> 
            <div class="row">
              <div class="col-5">
                <b>Produkt</b>
              </div>
              <div class="col-7">
                <div class="row align-items-center" :class="{ 'justify-content-center ' : step == 0, 'justify-content-end ' : step > 0}">
                  <div class="col-4 text-right">
                    <b>Ilość</b>
                  </div>
                  <div class="col-4 text-right">
                    <b>Cena</b>
                      </div>
                  <div class="col-4 text-right" v-if="step < 1"></div>
                </div>
              </div>
            </div>
          </div>
          <hr class="w-100 mb-0">
            <div class="col-12 item-Shoping-cart" v-for="(item, index) in cart_items" :key='index'>
                <div class="container ">
                <div class="row">
                    <div class="col-12 col-md-5">
                      <div class="row"> 
                        <div class="col-4 col-sm-3 col-lg-2">
                          <img :src="item.image" alt="" class="img-fluid"> 
                        </div>
                      <div class="col-8 col-sm-9 col-lg-10 d-flex">
                        <div class="product-name d-flex">
                        <a :href="item.url" :title='item.name'>
                            {{item.name}}   
                            <small v-for="(variant, index) in item.variant" class="ml-1 d-block"><b>{{variant[0]}}</b>: {{variant[1]}}</small>
                          </a>
                          </div>
                      </div>
                      </div>
                    </div>                
                    <div class="col-12 col-md-7">
                      <div class="row align-items-center min-height-1" :class="{ 'justify-content-center ' : step == 0, 'justify-content-end ' : step > 0}">
                      <div class="col-4 text-right">
                        <div class="cart-variant-price fd-column cart-quantity-items">
                          <div class="header-text   text-right">
                      <input v-if="step < 1" v-model="item.quantity" class="form-control text-center mr-3 quantity d-inline-block" type="number" min="1" step="1"
                          @change="update_item(item)" :max="item.max_buy" >
                            <span class="mt-2"> <div v-if="step >= 1" class="d-inline">{{item.quantity}}</div> szt.</span> </div>
                        </div>
                      </div>
                      <div class="col-4 text-right">
                      <div class="cart-variant-price">
                        {{ item.price*item.quantity | render_price }}
                        <div class="small" v-if="item.quantity > 1">za sztukę {{ item.price | render_price }}</div>
                      </div>
                      </div>
                      <div class="col-4" v-if="step < 1">
                        <div class="wrap-close">
                          <a @click="removeItem(item.pid)" class="removeItemFromCart delete">  
                            <svg  class="s28 delete-button"><use xlink:href="#close"/></svg>
                          </a>
                        </div>
                      </div>
                    </div>
                    </div>
                </div>
                </div>
                </div>
        </div> 
        <div v-if="cart && ((cart.count_items <= 0 && Number.isInteger(cart.count_items) ) || !cart.count_items)  || !cart" class="w-100">  
            <div class="col-12 no-items-cart my-5 py-5">
                <div class="text text-center my-5 py-5">
                    <h2 class="h-font">  
                      <svg  class="s20"><use xlink:href="#cart"/></svg>  
                    Brak produktów w koszyku.</h2>
                    <p>Sprwadź pełną ofertę <a href="/sklep">sklepu</a>.</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {
        mapGetters,
        mapActions
    } from 'vuex';
    
    export default {
    props: ['step'],
        data() {
            return {
                active: false,
                is_load: 0,
                update_timeout: null,
            }
        },
        computed: { 
            ...mapGetters(["cart_items", "cart", "delivery_update"]),
        },
        filters: {
            get_src_image: function (value) {
                if (!value) return ''
                value = JSON.parse(value);
                return value.src;
            },
            render_price: function (p) {
                p = parseFloat(p).toFixed(2).replace(".", ",") + ' zł';
                return p;
            }
        },
        mounted() { 
        },
        created() {
          this.$store.dispatch('get_cart').finally(() => (this.is_load=1));   
        }, 
        methods: {
            ...mapActions([
              // 'get_cart',
              'removeItem',
              // 'changeDelivery',
              'updateItemCart',
            ]),
            setValue() {
                window.clearTimeout(this.$options.valueTimeOut);
                this.$options.valueTimeOut = window.setTimeout(() => {
                    this.active = 1;
                }, 100);
            },
            resetValue() {
                window.clearTimeout(this.$options.valueTimeOut);
                this.$options.valueTimeOut = window.setTimeout(() => {
                    this.active = null;
                }, 100);
            },
         update_item(item){ 
              var self = this; 
              if(item.quantity < 1) item.quantity = 1; 
              clearTimeout(this.update_timeout); 
               this.update_timeout = setTimeout(function(prod = item) { 
                 self.updateItemCart(prod); 
               }, 400);   
            }  
        },
    }
</script>