<template>

<div v-if="cart && cart.count_items">
  <div class="cart-delivery">
    <div class="shoping-title mb-3">Przesyłka</div>
    <ul class="list-group list-cart" id="cart-delivery-list"> 
        <li v-for="item in get_avaible_deliverys.deliverys" v-bind:data-id="item.id" class="list-group-item delivery_input" v-if="get_avaible_deliverys.deliverys_avaible.includes(item.id)">
            <div class="custom-control custom-radio">
                <input @change="update_delivery(item.id)" :checked="current_delivery == item.id" type="radio" :id="item.slug" name="delivery" class="custom-control-input" :value="delivery.id">
                <label class="custom-control-label d-inline" v-bind:for="item.slug">{{item.name}} 
                    <span class="float-right" v-if="item.price <= 0"> Za darmo! </span>
                    <span class="float-right" v-else="item.price"> {{ item.price | render_price }} </span>
                </label>
            </div>
        </li> 
    </ul>
    <img id="photodelivery" src="" alt="">
  </div>
</div>

</template>


<script>
    import {
        mapGetters,
        mapActions
    } from 'vuex'
    export default {
    props: ['current'],
        data() {
            return {
                delivery: {},
                deliverys_avaible: {},
                current_delivery: this.current,
            }
        },
        computed: {
            ...mapGetters(["cart_items", "cart", 'delivery_update', 'get_avaible_deliverys']),
        },
        filters: {
            get_src_image: function (value) {
                if (!value) return ''
                value = JSON.parse(value);
                return value.src;
            },
            render_price: function (p) {
                p = parseFloat(p).toFixed(2).replace(".", ",") + ' zł';
                return p;
            }
        },
        mounted() { 
            this.getAvaibleDeliverys();
            this.get_cart();
        },
        created() {
        },
        methods: {
            ...mapActions(['get_cart', 'removeItem', 'changeDelivery', 'getAvaibleDeliverys']),
      
            update_delivery(id) {
                this.changeDelivery( 
                    {
                        id: id,
                    } 
                );
                
                this.getAvaibleDeliverys();
                this.delivery =  this.get_avaible_deliverys.deliverys;
                this.deliverys_avaible = this.get_avaible_deliverys.deliverys_avaible; 

            },
        },
    }
</script>