import axios from 'axios'

const state = {
    cart: null,
    delivery_update: {},
    get_avaible_deliverys: {},
    items: [],
    current: {},
};
const getters = {
    cart_items: (state) => state.items,
    cart: (state) => state.cart,
    current: (state) => state.current,
    delivery_update: (state) => state.delivery_update,
    get_avaible_deliverys: (state) => state.get_avaible_deliverys,
};
const actions = {
    async set_current({ commit }, current){
        commit('setCurrent', current);
    }, 
    async get_cart({ commit }){
        const response = await axios.get('/ajax/get_shopingcart'); 
        commit('setCart', response.data.cart);
        commit('setCartItems', response.data.cart_items);
        if(response.data.cart && response.data.cart.count_items == 0) { 
            let d = document;
            d.getElementById('go_next_cart').style.display = "none";     
            if(d.getElementById('shoping-cart-breadcrumb')) {
                d.getElementById('shoping-cart-breadcrumb').style.display = "none"; 
            }
        }
        return response.data;
    },
    async removeItem({
            commit,
            dispatch
        }, pid) {
         axios.post('/ajax/remove_item', {
                 pid: pid
             }).then(function (response) {
            actions.get_cart({  commit });
            actions.getAvaibleDeliverys({  commit }); 
         });
    },
    async addItem({
        commit
    }, product ) {
        if (!product.variant) product.variant = 0;
        if (!product.quantity) product.quantity = 1;
        const response = await axios.post('/ajax/add_to_cart', {
            'pid': product.pid,
            'variant': product.variant,
            'quantity': product.quantity,
        }).then(function (response) {
            var added = response.data;
            console.log(added);
            if (!added.error){
                function isIE() {
                    const ua = window.navigator.userAgent; //Check the userAgent property of the window.navigator object
                    const msie = ua.indexOf('MSIE '); // IE 10 or older
                    const trident = ua.indexOf('Trident/'); //IE 11
                    
                    return (msie > 0 || trident > 0);
                }
                
                // $("#added_to_card").modal("show"); 
                if(!isIE()) {
                    $("#added_to_card").modal("show"); 
                }
                var text_variant = ( added.display_variant ? '<small>'+added.display_variant+'</small>' : '')
                Vue.toasted.show('<div class="d-flex flex-row toasted-wrap"><div class="prod"><div class="img img-popup" style="background: url(' + added.img + ');"></div><div class="content"><small class="title text-success">Dodano do koszyka</small><b class="d-block">' + added.name + '</b>'+text_variant+'<small>' + added.cat + '</small></div><div class="quantity"><small> ' + added.added_quantity + 'x</small></div><div class="wrap"><div class="price">' + added.price + ' zł </div></div></div></div>');
            }
            else {
                 Vue.toasted.global.error({
                   message: added.error
                 });
            }
            actions.get_cart({
                commit
            });
        });
    },
    async updateItemCart({
        commit,
    }, item ) { 
        axios.post('/ajax/update_item', {
            id: item.pid,
            quantity: item.quantity,
        }).then(function (response) {
            if (response.data.error)
                Vue.toasted.show(response.data.error);

            actions.get_cart({  commit });
            actions.getAvaibleDeliverys({  commit }); 
        });
    },
    async changeDelivery({
        commit,
    }, id ) {
        axios.post('/ajax/delivery_update', {
            delivery: id.id
        }).then(function (response) {
            actions.get_cart({  commit });
        commit('setDelivery', response.data);
    });
    },
    async getAvaibleDeliverys({
        commit,
    }){  
        const response = await axios.get('/ajax/avaible_deliverys');   
        commit('avaibleDeliverys', {
            'deliverys': JSON.parse(JSON.stringify(response.data.deliverys)),
            'deliverys_avaible': response.data.deliverys_avaible,
        });
    },
};
const mutations = {
    setCart: (state, cart) => (state.cart = cart),
    setCartItems: (state, items) => (state.items = items),
    setCurrent: (state, current) => (state.current = current),
    setDelivery: (state, delivery_update) => (state.delivery_update = delivery_update),
    avaibleDeliverys: (state, get_avaible_deliverys) => (state.get_avaible_deliverys = get_avaible_deliverys),
};

export default {
    state,
    getters,
    actions,
    mutations
}