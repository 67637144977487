<template> 
    <div class="row pt-5 mb-5"> 
        <div class="col-6 col-md-6">
            <a :href="back_route">
                   <div class="button button--lightgray">  
                        <svg  class="s16"><use xlink:href="#arrow-left"/></svg>   
                       Wróć do zakupów</div> 
            </a>
        </div>
        <div v-if="cart && cart.count_items > 0" class="col-6 col-md-6" id="go_next_cart">
            <a :href="next_route">
                <button class="btn button button--blue float-right">Dalej  
                  <svg  class="c2 s16"><use xlink:href="#arrow-right"/></svg> 
                </button>
            </a>
        </div>  
    </div> 
</template>
<script>
    import {
        mapGetters, 
    } from 'vuex'

    export default {
            props: ['next_route', 'back_route'],
            computed: {
                ...mapGetters([ "cart"]),
            },
            mounted() { 
                
            },
    }
</script>