<template>
    <div class="slider_product">
        <div class="position-relative">
            <div class="mb-3 swiper-container gallery-top swiper-container-horizontal rounded" >
            <div class="swiper-wrapper"  v-viewer="{movable: false, url: 'data-source',zIndex: 99999,toolbar: true,title: false,navbar: false,}" :options="this.options"> 
                <div class="swiper-slide" v-for="(item,index) in product_photos" :data-variant="item.variant" :data-is_variant="item.is_variant"> 
                <!-- <div class="swiper-slide" v-for="(item,index) in product_photos" :data-variant="item.variant" :data-is_variant="item.is_variant" @click="show" v-viewer="{movable: false}">  -->
                    <img :src="item.src" :alt="item.alt" :data-source="item.full" class="w-100">
                    <!-- {{ item }} -->
                </div>
                
            </div> 
        </div> 
        <a v-if="product_photos.length > 1" class="swiper-button-prev i-svg i-svg--white arrow-left"></a>
        <a v-if="product_photos.length > 1" class="swiper-button-next i-svg i-svg--white arrow-right"></a>
        </div>

        <div class="position-relative">
            <div class="swiper-small swiper-container" v-if="this.product_photos.length >= 5">
                <div class="swiper-wrapper"> 
                    <div class="swiper-slide" v-for="item in product_photos"> 
                        <div class="product_photo"> 
                            <img :src="item.src" :alt="item.alt" class="w-100"> 
                            <i class="product-active-gallery"></i> 
                        </div>
                    </div>

                </div> 
            </div>
                <!-- <a class="swiper-button-prev swiper-button-prev1 i-svg i-svg--white arrow-left  i-svg--s28"></a>
                <a class="swiper-button-next swiper-button-next1 i-svg i-svg--white arrow-right  i-svg--s28"></a>  -->
        </div>
    </div>
</template>
<script>
    import Swiper from "../import/swiper.min.js";
    import { defineComponent } from 'vue'
    import 'viewerjs/dist/viewer.css'
    import { directive as viewer } from "v-viewer"

    import {
        mapGetters, 
    } from 'vuex'; 

    // viewer.setDefaults({
    // // navbar: false,
    // toolbar: false,
    // title: false,
    // url: 'data-source',
    // // movable: false,
    // zIndex: 99999
    // });

    
    export default {
        props: ['default_photos'],
        directives: {
        viewer: viewer({
            debug: false,
            url: 'data-source'
        })
    },
        data() {
            return {
                product_photos: JSON.parse(this.default_photos),
                galleryThumbs: null,
                swiper_slider: null,
                variant_photo: null,
                options: {
                    navbar: false,
                    title: false,
                    toolbar: false,
                    title: false,
                    url: 'data-source',
                },
            }
        },
        mounted: function() { 
            if(this.product_photos.length >= 5){
                this.initSwiperSmall();
            }
            this.initSwiper();
            
            // if(this.galleryTop) {
            if(this.product_photos.length >= 5){
                this.galleryTop.params.control = this.galleryThumbs;
                this.galleryThumbs.params.control = this.galleryTop;
            }
        },
        computed:  {
             ...mapGetters([ "current"]),
        },
        updated: function () {
            this.initSwiper();
            this.initSwiperSmall();
        },
        methods: {
            // show () {
            //     // const viewer = this.$el.querySelector('.images').$viewer
            //     viewer.show()
            // },
            inited (viewer) {
                this.$viewer = viewer
            },
            show () {
                this.$viewer.show()
            },
            initSwiperSmall: function() {
                if(this.galleryThumbs != null) {
                    this.galleryThumbs.destroy();
                }
                this.galleryThumbs = new Swiper('.swiper-small', {
                    slidesPerView: 5,
                    spaceBetween: 10,
                    loop: false,
                    centeredSlides: false,
                    navigation: {
                        nextEl: '.swiper-button-next1',
                        prevEl: '.swiper-button-prev1',
                    },
                    breakpoints: {
                        400: {
                            slidesPerView: 3,
                        },
                        768: {
                            slidesPerView: 4,
                        },
                        992: {
                            slidesPerView: 5,
                        }
                    },
                });
            },
            initSwiper: function() {
                if(this.galleryTop != null) {
                    this.galleryTop.destroy();
                }
                if(this.product_photos.length > 1) {
                this.galleryTop = new Swiper('.gallery-top', {
                    slidesPerView: 'auto',
                    loop: true,
                    watchOverflow: true,
                    thumbs: {
                        swiper: this.galleryThumbs,
                    },  
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                });
                }
            },
        },
    }
</script>
<style scoped>
    .swiper-small {
        position: relative;
    }
    .product_photo{
        background-size: cover;
        opacity: 0.5;
        background-color: black;
        cursor: pointer;
    }
    .swiper-slide-thumb-active .product_photo {
        opacity: 1;
    }
    .gallery-thumbs .swiper-slide {
        width: 20%;
        height: 100%;
    }
</style>