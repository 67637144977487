<template>
    <div class="row my-4">  
    <div class="col-12 col-xl-4 p-0">
      <nav class="bg_c7 h-100 align-items-center py-4">
      <div id="rentTabs" role="tablist" class="d-flex flex-column justify-content-center h-100 align-items-center">
        <a v-for="(rentType, tabIndex) in rentTypes"
          :key="tabIndex"
          class="d-flex mb-2"
          :class="{ active: tabIndex === activeTabIndex }"
          @click="changeTab(tabIndex)">
        <button class="button button--border-orange p-2" :class="{ active: tabIndex === activeTabIndex }" style="width: 200px;" v-if="rentType">{{ find_attibute_value(rentType).name }}</button>
        </a>
      </div>
    </nav>
    </div>
    <div class="col-12 col-xl-8 bg-white py-4">
      <div class="tab-content" id="rentTabContent">
      <div v-for="(rentType, tabIndex) in rentTypes"
        :key="tabIndex"
        class="tab-pane fade"
        :class="{ show: tabIndex === activeTabIndex, active: tabIndex === activeTabIndex }"
        role="tabpanel">



        <table class="w-100 d-none d-lg-table">
      <thead>
        <tr>
          <th class="py-1 px-2"></th> 
          <th class="py-1 px-2" v-for="(variant, index) in getVariantsByRentType(rentType)" :key="index">
            <span class="c1" v-if="variant">{{ find_attibute_value(variant.attr.czas).name }} </span>
          </th>
        </tr>
      </thead>
      <tbody>
            <tr>
                <td class="py-1 px-2"><b class="c1">CENA</b></td>
                <td class="py-1 px-2" v-for="(variant, index) in getVariantsByRentType(rentType)" :key="index">
                    <!-- <div v-if="variant">{{ variant.price ? render_price(variant.price ) : 'indywidualnie' }} {{variant.price_promo}}</div> -->
                    <div v-if="variant" class="position-relative">
                        <template v-if="variant.price_promo">
                            {{ render_price(variant.price_promo) }} <s style="margin-top: -13px;position: absolute;font-size: 0.8rem;left: 0;">{{ variant.price ? render_price(variant.price) : 'indywidualnie' }}</s>
                        </template>
                        <template v-else >
                          {{ variant.price ? render_price(variant.price) : 'indywidualnie' }}
                        </template>
                    </div>
                    <!-- <div v-if="variant">{{ render_price(variant.price ) }} </div> -->
                </td>
            </tr>
            <tr>
                <td class="py-1 px-2"><b class="c1">Limit km</b></td>
                <td class="py-1 px-2" v-for="(variant, index) in getVariantsByRentType(rentType)" :key="index">
                    <div v-if="variant">{{ find_attibute_value(variant.attr.limit).name }} </div>
                </td>
            </tr>
            <tr>
                <td class="py-1 px-2"><b class="c1 py-3">Dodatkowy km</b></td>
                <td colspan="5" class="py-1 px-2"><div class="dwa"> 
                    {{ about_car.limit_km == 0 || about_car.limit_km == undefined ? 'Ustalane indywidualnie' : about_car.limit_km + ' zł' }}
                </div></td>
            </tr>
            <tr>
                <td class="py-1 px-2"><b class="c1">Kaucja</b></td>
                <td colspan="5" class="py-1 px-2"><div class="dwa"> 
                    {{ about_car.deposit == 0 || about_car.deposit == undefined ? 'Ustalane indywidualnie' : about_car.deposit + ' zł' }}
                
                </div></td>
            </tr>
            <tr>
                <td class="py-1 px-2"><b class="c1">Minimalny wiek</b></td>
                <td colspan="5" class="py-1 px-2"><div class="dwa">

                    {{ about_car.limit_km_day == 0 || about_car.limit_km_day == undefined ? 'Ustalane indywidualnie' : about_car.limit_km_day + ' lat' }}
                    </div></td>
            </tr>
            <tr>
                <td class="py-1 px-2"><b class="c1">Koszt podstawienia</b></td>
                <td colspan="5" class="py-1 px-2"><div class="dwa">{{ about_car.price_limit_km == 0 || about_car.price_limit_km == undefined ? 'Ustalane indywidualnie' : about_car.price_limit_km }}</div></td>
            </tr>
      </tbody>
    </table>

    <div class="d-block d-lg-none">
        <div class="d-flex w-100 justify-content-center pb-4 text-center">
            <div class="">
                <div>&nbsp;</div>
                <div v-for="(variant, index) in getVariantsByRentType(rentType)" :key="index">
                    <div class="py-2 c1 bold" v-if="variant">{{ find_attibute_value(variant.attr.czas).name }} </div>
                </div>
            </div>
            <div class="px-2 px-sm-4">
                <b class="c1 py-2 px-2">Cena</b>
                <div class="py-2 px-2 position-relative" v-for="(variant, index) in getVariantsByRentType(rentType)" :key="index">
                    <div v-if="variant">
                        <template v-if="variant.price_promo">
                            {{ render_price(variant.price_promo) }} <s style="margin-top: -13px;position: absolute;font-size: 0.8rem;right: 0; color: #777">{{ variant.price ? render_price(variant.price) : 'indywidualnie' }}</s>
                        </template>
                        <template v-else >
                          {{ variant.price ? render_price(variant.price) : 'indywidualnie' }}
                        </template>
                        <!-- {{ variant.price ? render_price(variant.price ) : 'indywidualnie' }}  -->
                    
                    </div>
                </div>
                <!-- <div v-if="variant" class="py-1 px-2 position-relative"  :key="index">
                    <template v-if="variant.price_promo">
                        {{ render_price(variant.price_promo) }} <s style="margin-top: -13px;position: absolute;font-size: 0.8rem;left: 0;">{{ variant.price ? render_price(variant.price) : 'indywidualnie' }}</s>
                    </template>
                    <template v-else >
                      {{ variant.price ? render_price(variant.price) : 'indywidualnie' }}
                    </template>
                </div> -->
            </div>
            <div class="">
                <b class="py-1 px-2 c1">Limit km</b>
                <div class="py-2 px-2" v-for="(variant, index) in getVariantsByRentType(rentType)" :key="index">
                    <div v-if="variant">{{ find_attibute_value(variant.attr.limit).name }} </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-6 text-center">
                <div class="py-1 px-2"><b class="c1 py-3">Dodatkowy km</b></div>
                <div class="py-1 px-2"><div class="dwa"> 
                    {{ about_car.limit_km == 0 || about_car.limit_km == undefined ? 'Ustalane indywidualnie' : about_car.limit_km + ' zł' }}
                </div></div>
            </div>
            <div class="col-12 col-sm-6 text-center">
                <div class="py-1 px-2"><b class="c1">Kaucja</b></div>
                <div class="py-1 px-2">
                    <div class="dwa"> 
                    {{ about_car.deposit == 0 || about_car.deposit == undefined ? 'Ustalane indywidualnie' : about_car.deposit + ' zł' }}
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6 text-center">
                <div class="py-1 px-2"><b class="c1">Minimalny wiek</b></div>
                <div class="py-1 px-2">
                    <div class="dwa">
                    {{ about_car.limit_km_day == 0 || about_car.limit_km_day == undefined ? 'Ustalane indywidualnie' : about_car.limit_km_day + ' lat' }}
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6 text-center">
                <div class="py-1 px-2"><b class="c1">Koszt podstawienia</b></div>
                <div class="py-1 px-2"><div class="dwa">{{ about_car.price_limit_km == 0 || about_car.price_limit_km == undefined ? 'Ustalane indywidualnie' : about_car.price_limit_km + ' km'}}</div></div>
            </div>
        </div>
    </div>



      </div>
    </div>
    </div>
  </div>
</template>



<script>
    import {
        mapGetters,
        mapActions
    } from 'vuex';

    export default {
        props: ['pid', 'price', 'variants', 'attributes_name', 'product' , 'attributes_array'],
        data() {
            return {
                activeTabIndex: 0,
              active: false,
              quantity: 1,
              all_variants: JSON.parse(this.variants),
              temp_all_variants: JSON.parse(this.variants),
              name_attributes: JSON.parse(this.attributes_name),
              about_car: JSON.parse(this.pid),
              attributes: [],
              template_attributes: [],
              attr: [],
              max_quantity: 0,
              selects: {},
              selects_html : [],
              variant: null,
              current_price: null, 
              old_price: null, 
              current_variant: null,  
              image: null,  
              current_product: JSON.parse(this.product),
            }
        },
        filters: {
            render_price: function(p) {
                p = parseFloat(p).toFixed(2).replace('.', ',') + ' zł';
                return p;
            },
            get_price_first: function (p) {
                p = parseFloat(p).toFixed(2).split('.');
                return p[0];
            },
            get_price_last: function (p) {
                p = parseFloat(p).toFixed(2).split('.');
                return p[1];
            },
        },
        computed: {
            ...mapGetters(["cart_items", "cart"]),
            rentTypes() {
      return Array.from(new Set(this.all_variants.map((variant) => variant.attr.wynajem)));
    },
        },
        mounted: function() { 
            this.load_variants();
            this.find_variant();
        },
         created() {
                var self = this; 
            window.onpopstate = function(event) {
            var pageURL = document.location.pathname;
            var location =  pageURL.split("/").pop();
            console.log(document.location);
            var item =  self.all_variants.find(item => item.url == location ? item : '' );   
                for (const [key, value] of Object.entries(item.attr)) { 
                    self.selects[key] = value; 
                    self.find_variant(key, false);
                }
            };
        },
        methods: {
            ...mapActions(['addItem', 'set_current']),
            changeTab(index) {
      this.activeTabIndex = index;
    },
            hasRentType(rentType) {
      return this.rentTypes.includes(rentType);
    },

    render_price: function(p) {
                p = parseFloat(p) + ' zł';
                // p = parseFloat(p).toFixed(2).replace('.', ',') + ' zł';
                return p;
            },
    // Pobierz warianty dla określonego typu wynajmu
    getVariantsByRentType(rentType) {
      return this.all_variants.filter((variant) => variant.attr.wynajem === rentType);
    },
            addtocart(id) {
                this.addItem( 
                    {
                        pid: id,
                        quantity: parseInt(this.quantity),
                        variant: this.variant
                    } 
                );
            },
            setValueSelect( slug , value ){
                let element = event.currentTarget
                this.selects[slug] = value;
                this.selects_html = [];
                this.find_variant(slug);
                this.$forceUpdate() 
            },
            selectClick(event){
                let element = event.currentTarget
                let id = element.getAttribute('select');
                if( this.selects_html.indexOf( id ) < 0 )
                    this.selects_html.push( id ); 
                else{
                    let index = this.selects_html.indexOf( id );
                    if (index > -1) {
                        this.selects_html.splice(index, 1);
                    }
                }
                
                this.$forceUpdate() 
            },
            updateQuantity(event){
                const value = event.target.value 
                if (this.current_variant.in_stock < this.quantity) {
                    this.quantity = this.current_variant.in_stock
                }
                this.$forceUpdate()
            },
            find_attibute(slug) {
                var attr = JSON.parse(this.attributes_array); 
                return attr.find(el => el.slug == slug);
            },
            find_attibute_by_slug(slug){
                var attr = JSON.parse(this.attributes_array); 
                return attr.find(x => x.slug === slug);
            },
            find_attibute_value(find) { 
                return this.name_attributes.find(x => x.id === find);
            },


            load_variants: function(){
                var self = this; 
                if(Object.keys(self.all_variants).length) {
                    self.all_variants.forEach(function (item, index) {
 
                        var lenghts = Object.keys(item.attr).length;
                        self.all_variants.forEach(function (item, index) {
                            for (var i = 0; i < lenghts; i++) {
                                if(!self.attr[Object.keys(item.attr)[i]]) {
                                    self.attr[Object.keys(item.attr)[i]] = [];
                                }
                                if( !self.attributes.find(x => x.value === Object.values(item.attr)[i])  ){
                                    self.attributes.push( { 'key'  : Object.keys(item.attr)[i], 'value' : Object.values(item.attr)[i] });
                                    self.attr[Object.keys(item.attr)[i]].push(Object.values(item.attr)[i]);
                                }
                            }   
                        })
                        for(var i = 0; i<lenghts; i++) {
                            if(Object.keys(self.selects).length < lenghts || Object.keys(self.selects).length == undefined) {
                                self.selects[Object.keys(item.attr)[i]]  = Object.values(item.attr)[i]
                            }
                        }
                    })
                    var grouped = _.mapValues(_.groupBy(self.attributes, 'key'),
                            clist => clist.map(car => _.omit(car, 'key')));
                    self.attributes = (grouped);


                } else { 
                        self.current_price = this.price; 
                }
            },
            find_variant: function(slug = null, change_url = true){ 
                var self = this;
                var item = self.all_variants.find(item => ( _.isEqual(self.selects, item.attr) ) ? (self.variant = item.id, self.current_price = item.price, self.current_variant = item, self.current = item) : '' ); 
                this.set_current({
                    variant: item
                });

                if(item.name) $("#product_name").html(item.name);
                if(item.url && change_url)  window.history.pushState(item.name, item.name, item.url);
                // if(item.fulldesc)  $("#fullpdescription > div").html(item.fulldesc);
                if(item.in_stock < self.quantity) self.quantity = item.in_stock;
                if(self.template_attributes.length == 0) self.template_attributes = self.attributes;
                this.max_quantity = item.in_stock;
                if(slug != null){
                    var attr_val = this.selects[slug];
                    self.attributes = self.template_attributes;
                    
                    var find_variants =   self.temp_all_variants.filter(function(variant, index){
                        if(variant.attr[slug] == attr_val){

                            self.image = variant.image_full;
                            return variant;
                        }
                    }); 

                    var ia = 0;
                    Object.keys(self.attributes).forEach(function(i) { 
                        if(i != slug)
                            self.attributes[i] = [];
                        ia++;
                    }); 

                    find_variants.forEach(function(variant) {  
                        Object.keys(variant.attr).forEach(function(item) {  
                            
                            if(!self.attributes[item]) self.attributes[item] = []; 

                            if(!self.attributes[item].find(el => el.value == variant.attr[item]))
                                self.attributes[item].push({ 'value' : variant.attr[item]});
                        });  
                    });                 
                
                    if(!item){
                        Object.keys(self.attributes).forEach(function(i) {  
                            if(ia != 0  && i != slug){
                                self.selects[i] = self.attributes[i][0].value; 
                            } 
                            ia++;
                        }); 
                        this.find_variant();
                    }
                } 
            }
        }
    }
</script>


<style scoped>
    @media (max-width: 1199px) {
.mobile_hide {
        display: none;
    }
}

</style>