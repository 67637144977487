<template>
        <div v-if="cart && cart.count_items">
        <div class="row check">
        <div class="col-md-12 float-right">
        <div class="text-card">Dostawa </div>
            <span id="checkOutDelivery" class="float-right text-center"> <span >{{cart.delivery_price | render_price}}</span>
                <br>
            </span>
            <hr>
        </div> 
        <div class="col-md-12 float-right"><div class="text-card"> Wartość zamówienia:</div> <span id="checkOut" class="float-right ">{{cart.price | render_price}}</span></div>
        <div class="col-md-12" v-if="cart.copupon"><div class="pull-left"><a :href="route_delete_coupon" title="usuń kod"><i aria-hidden="true" class="fa fa-times"></i></a> Kod rabatowy (<strong>{{cart.copupon.code}}</strong>):</div> <div id="drop_price" class="float-right">{{cart.copupon.price_down | render_price}}</div></div>
            <div class="col-md-12 float-right">
                <div class="text-card"> <span class="final-price">Razem:</span></div>
                <span id="checkOut" class="float-right final-price"> <span>{{cart.total_price | render_price}}</span>  </span>
            </div>
        </div>
        </div>
</template>


<script>
    import {
        mapGetters,
        mapActions
    } from 'vuex'
    export default {
        props: ['route_delete_coupon'],
        computed: {
            ...mapGetters(["cart_items", "cart", 'delivery_update']),
        },
        filters: {
            render_price: function (p) {
                p = parseFloat(p).toFixed(2).replace(".", ",") + ' zł';
                return p;
            }
        },
        mounted() { 
            this.get_cart();
        },
        methods: {
            ...mapActions(['get_cart', 'removeItem', 'changeDelivery']),
      
            update_delivery(id) {
                this.changeDelivery( 
                    {
                        id: id,
                    } 
                );
            },
        },
    }
</script>