// import Vuex from 'vuex';
// import Vue from 'vue';
// import shoping_cart from './modules/shoping_cart';
// // import VueDependOn from 'vue-dependon'
// // Vue.use(VueDependOn)
// // load vuex
// Vue.use(Vuex);

// export default new Vuex.Store({
//     modules:{
//         shoping_cart
//     }
// });
import Vue from 'vue';
window.Vue = require('vue').default;
import shoping_cart from './modules/shoping_cart';
// import VueDependOn from 'vue-dependon'
// Vue.use(VueDependOn)
// load vuex
// Vue.use(Vuex);

import { createStore } from 'vuex';

export const store = createStore({
    modules:{
        shoping_cart
    }
})