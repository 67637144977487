<template>
    <div>             
        <a href="/koszyk" class="nav-link pr-0" rel="nofollow"  @mouseenter="setValue()"   @mouseleave="resetValue" title="Mój koszyk"> 
                <svg  class="s24"><use xlink:href="#cart"/></svg> 
            <span id="cart_total_items"  class=" cart_total_items" v-if="cart && cart.count_items > 0" >{{ cart.count_items }}</span>
        </a>
        <ul id="block_cart"  v-if="cart && active && cart.count_items > 0"  @mouseenter="setValue()" @mouseleave="resetValue"> 
        <div class="container" >
            <div class="row">
                <div class="col-12 pt-3 pb-3">
                    <div class="wrapper"> 
                        <div class="quantity">
                            Ilość produktów: <span id="cart_total_items" class="cart_total_items_inside">
                                {{ cart.count_items }} </span>
                        </div>
                        <div class="total-price">
                            Całkowita cena z przesyłką: <span class="cart_total_payment_inside">
                                {{ cart.total_price | render_price }} </span>
                        </div>
                    </div> 
                    <div class="products">
                        <div class="top-cart-item  mt-3" v-for="(item, index) in cart_items" :key='index'>
                            <div class="image"><img v-bind:src="item.image"></div>
                            <div class="product-name">
                                <a v-bind:href="item.url" title="item.category_name" class="title">
                                    {{item.name}} 
                                    <small class="d-block" v-for="item in item.variant"><b>{{item[0]}}</b>:{{item[1]}}</small>
                                </a>
                                <div class="cat">{{ item.category_name }}</div>
                                <div class="price"> Cena: <span>{{ item.price | render_price }} </span> x{{ item.quantity }}</div>
                            </div>
                            <div v-on:click="removeItem(item.pid)" v-if="step < 1" class="btn-del mt-4"> 
                                <svg  class="c1 s28"><use xlink:href="#close"/></svg> 
                            </div>
                        </div>
                    </div>
                    <div class="submit-cart  float-right">
                        <a ref="nofollow" href="/koszyk" title="Koszyk">
                            <button class="button button--lightgray s3">Zobacz koszyk   
                                <svg  class="s16 c1"><use xlink:href="#arrow-right"/></svg> 
                                 </button> 
                        </a>
                    </div>
                </div>
            </div>
        </div> 
           </ul>
    </div>
</template> 
<script>
    import { mapGetters, mapActions } from  'vuex'
    export default {
    props: ['step'],
        data() {
            return { 
                active: false
            }
        },
        computed:  {
            ...mapGetters([ "cart_items", "cart"]),
        }, 
        filters: {
            get_src_image: function (value) {
                if (!value) return ''
                value = JSON.parse(value);
                return value.src;
            },
            render_price: function (p) {
                p = parseFloat(p).toFixed(2).replace(".", ",") + ' zł';
                return p;
            }
        },
        mounted() {
            this.get_cart(); 
        },
        created() { 
        },  
         methods: { 
            ...mapActions(['get_cart']),
            ...mapActions(['removeItem']), 
            setValue() {
            window.clearTimeout(this.$options.valueTimeOut);
                this.$options.valueTimeOut = window.setTimeout(() => {
                    this.active = 1;
                }, 100);
            },
            resetValue() {
            window.clearTimeout(this.$options.valueTimeOut);
                this.$options.valueTimeOut = window.setTimeout(() => {
                    this.active = null;
                }, 100);
            }
         },
    }
</script>