<template>
    <div class="mt-3"> 
       <a :href="url" v-if="attr"><button class="btn btn-outline-dark btn-sm mx-auto d-block">
            Wybierz wariant
        </button></a>
        <button v-on:click="addItem({pid:pid})" class="btn button button--blue btn-sm mx-auto d-block" v-else>
            <i aria-hidden="true" class="fa fa-Shopping-cart"></i> Do koszyka
        </button>
    </div>
</template>
<script>
import { mapGetters, mapActions } from  'vuex';
export default {
         props: ['pid', 'attr', 'url'],
        data() {
            return { 
                active: false
            }
        },
         computed:  {
             ...mapGetters([ "cart_items", "cart"]),
         }, 
         methods: {
            ...mapActions(['addItem']),
         },
}
</script>